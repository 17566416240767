/* contact */

#contact {
  position: relative;
  top: 96px;
  display: flex;
  justify-content: space-evenly;
  padding: 60px;
  background: #e3e8ee;
  overflow: hidden;
}

.basic-info {
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.get-in-touch {
  /* margin: 80px 0 100px 0; */
}
.get-in-touch h2 {
  font-weight: 700;
  font-size: 40px;
  color: #295135;
  margin-bottom: 24px;
  text-transform: capitalize;
  margin-top: 20px;
}
.get-in-touch p {
  font-weight: 500;
  font-size: 32px;
  color: #0d1321;
}
.contact-info {
  width: 80%;
  padding: 8px 16px;
  background: #d9d9d9;
  margin: 40px 0 40px 0;
}
.contact-info ul {
  list-style: none;
}
.contact-info ul li {
  padding: 5px;
}
#location {
  color: #f24e1e;
}
#msg {
  color: #748cab;
}
#phone {
  color: #0a3772;
}
.social-media {
  width: 20vw;
  /* height: 4vw; */
  background: rgba(139, 159, 185, 0.5);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  min-width: 238px;
  min-height: 48px;
  cursor: pointer;
  margin: 25px 0 25px 0;
}
.social-media img {
  width: 65%;
  min-width: 40px;
  padding: 3px;
}
.social-media #yt {
  width: 65%;
  min-width: 60px;
  padding: 3px;
}
.copy-right {
  padding: 10px;
  color: #907a50;
  /* margin-top: 28px; */
}
.contact-us {
  display: flex;
  justify-content: center;
}
.contact-us img {
  height: 100%;
}
.contact-us .circle {
  width: 45vw;
  height: 45vw;
  background-image: url(../../../public/img/images/contact/Subtract.png);
  background-size: 100%;
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  min-width: 700px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  padding: 130px 50px 150px 50px;
  justify-content: space-between;
  align-items: center;
}
.circle h2 {
  font-weight: 700;
  font-size: 40px;
  color: #0d1321;
}
.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}
.contact-form input {
  font-weight: 500;
  font-size: 32px;
  color: #0d1321;
  padding: 8px 16px;
  border: 1px solid #dfb57e;
  border-radius: 8px;
  background: inherit;
}
.circle button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 80px;
  background: #cabf62;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 24px;

  color: #0d1321;
}

@media screen and (max-width: 1220px) {
  #contact {
    flex-direction: column-reverse;
    padding: 80px 20px 30px 20px;
  }
  .contact-us .circle {
    min-width: 678px;
    min-height: 678px;
  }
  .basic-info {
    width: 100%;
    align-items: center;
    text-align: center;
    margin-top: 55px;
  }
  .get-in-touch {
    text-align: center;
  }
  .contact-info {
    width: 380px;
  }
}

@media screen and (max-width: 750px) {
  .circle h2 {
    font-size: 6vw;
  }
  .circle button {
    font-size: 4vw;
    padding: 6px 30px;
    margin-top: 5px;
  }
  .contact-form {
    gap: 2%;
  }
  .contact-form input {
    font-size: 4.2vw;
    padding: 6px 14px;
  }
  .contact-us .circle {
    min-width: unset;
    min-height: unset;
    width: 92vw;
    height: 92vw;
    padding: 20% 50px 20% 50px;
  }
  .get-in-touch h2 {
    font-size: 7vw;
  }
  .get-in-touch p {
    font-size: 5vw;
  }
}
@media screen and (max-width: 500px) {
  .contact-us img {
    width: 100%;
  }
}
