/* signup section */

#signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  gap: 18px;
  margin-top: 110px;
}

#signup-section h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}
#signup-section h3 {
  font-size: 24px;
  line-height: 29px;
  text-align: center;

  color: #34a853;
}
#signup-section h3 span {
  padding: 10px;
  background: #ffffff;
  border-radius: 8px;
}
/* login modal */
.signup-modal {
  width: 50%;
  background-color: #907a50;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.credentials {
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.sec {
  display: flex;
  gap: 35px;
  justify-content: center;
}
.sec p {
  width: 50%;
}
.input {
  background: #ffffff;
  border: 1px solid #94a3b8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 16px 12px;
  width: 100%;
  margin-top: 12px;
}
.Signup {
  font-size: 20px;
  padding: 8px 24px;
  background: #cabf62;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}
.signup-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
}
.signup-footer p {
  font-size: 16px;
  text-decoration-line: underline;
  color: #dfb57e;
  cursor: pointer;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  z-index: 5;
}
.hidden {
  display: none;
}

@media screen and (max-width: 1100px) {
  .signup-modal {
    width: 75%;
  }
}
@media screen and (max-width: 800px) {
  .sec {
    flex-direction: column;
  }
  .sec p {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .signup-modal {
    width: 100%;
  }
  .input {
    padding: 10px 12px;
  }
}
