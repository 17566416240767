* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

body {
  width: 100%;
  background: #fdfae3;
}

/* Footer */
#footer {
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  background: rgba(85, 85, 85, 0.95);
  color: white;
  overflow: hidden;
}
#footer .top-footer,
#footer .bottom-footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 13px 0;
}
#footer .top-footer .top-left-footer ul li,
#footer .top-footer .top-right-footer ul li {
  list-style: none;
  padding: 5px;
}
#footer .top-footer .top-left-footer ul li a {
  text-decoration: none;
  color: white;
}
#footer .top-footer .top-mid-footer {
  border-left: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
#footer .top-footer .top-right-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
#location {
  color: #f24e1e;
}
#msg {
  color: #748cab;
}
#phone {
  color: #0a3772;
}
#footer .top-footer .top-right-footer ul {
  width: 100%;
  margin-left: 30%;
  text-align: center;
}
#footer .top-footer .top-right-footer ul li {
  /* text-align: center; */
}

#footer .top-footer .top-mid-footer a img {
  border: none;
  height: 120px;
  width: 446px;
}

#footer .bottom-footer {
  padding: 37px 0 29px 0;
}
#footer .bottom-footer .bottom-mid-footer {
  justify-content: center;
  align-items: center;
  display: flex;
}
#footer .bottom-footer .bottom-mid-footer .logos {
  /* width: 20vw;
      height: 4vw; */
  background: rgba(139, 159, 185, 0.5);
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  min-width: 238px;
  min-height: 48px;
  cursor: pointer;
}
#footer .bottom-footer .bottom-mid-footer .logos img {
  width: 65%;
  min-width: 40px;
  padding: 3px;
}
#footer .bottom-footer .bottom-right-footer {
  text-align: end;
}
#footer .bottom-footer .bottom-mid-footer .logos #yt {
  width: 65%;
  min-width: 60px;
  padding: 3px;
}

@media screen and (max-width: 1530px) {
  #first-two-col {
    flex-wrap: wrap;
    justify-content: space-around;
  }
  #box {
    margin: 17px;
  }
}

@media screen and (max-width: 500px) {
  #box {
    min-width: 340px;
    min-height: 490px;
  }
  #img {
    min-width: 200px;
    min-height: 200px;
  }
  #enq-text {
    font-size: 18px;
  }
  #heading-text-box {
    font-size: 24px;
  }
  #desc-para {
    font-size: 21px;
  }
  .course {
    padding: 5px 12px;
    font-size: 15px;
  }
}

@media screen and (max-width: 1100px) {
  #box {
    width: 71vw;
  }
  #img {
    width: 28vw;
    height: 28vw;
  }
  #para2 {
    width: 100%;
    text-align: center;
  }
  .section-body {
    padding: 30px;
  }
}
@media screen and (max-width: 985px) {
  #footer .bottom-footer,
  #footer .top-footer {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .top-left-footer {
    text-align: center;
  }
  #footer .top-footer .top-mid-footer {
    border: none;
  }
  #footer .top-footer .top-right-footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  #footer .top-footer .top-right-footer ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #footer .bottom-footer {
    gap: 15px;
  }
  #footer .bottom-footer .bottom-left-footer {
    text-align: center;
  }
}
