/* .container {
  width: 30vw !important;
  height: 15vh !important;
  left: 10px !important;
} */

.section-body {
  width: 100%;
  background: #fdfae3;
  margin-top: 117px;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#heading-text-box-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

#head {
  font-weight: 600;
  font-size: 60px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #295135;
}
/* frame1 */
.section-body {
  padding: 60px;
  margin-top: 120px;
}
#rect {
  width: 164px;
  height: 4px;
  left: 0px;
  background: #b59b6b;
  border-radius: 8px;
}

#para {
  font-weight: 400;
  font-size: 24px;
  line-height: 180%;
  text-align: center;
  text-transform: capitalize;
  color: #353a46;
  order: 1;
  margin-top: 32px;
}

/* frame2 */
#frame2 {
  margin: 48px 0 48px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
}

#para2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-transform: capitalize;
  color: #295135;
  width: 66%;
  border-bottom: 4px solid #b59b6b;
  border-radius: 8px;
  padding: 16px;
}

/* first-two-col */
#first-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin: 50px;
  flex-wrap: wrap;
  /* background: #D9D9D9; */
}

#boxs {
  position: relative;
  width: 70vw;
  height: 30vh;
  /* min-width: 451px; */
  left: 180px;
  

  min-height: 572px;
  background: #524c43;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 10px;
}

/* image  */
#img {
  width: 18vw;
  height: 18vw;
  min-width: 280px;
  min-height: 280px;
  border: 9px solid #ffffff;
  border-radius: 300px;
  margin: 25px 0 17px 0;
}

/* inner-section-text */
#inner-section {
  /* position: absolute; */
  /* width: 547px;
    height: 276px; */
  /* left: 0px;
    top: 171px; */
}
#inner-section ul {
  text-align: center;
  margin: 12px;
}
/* heading-text-in box  */

#heading-text-box {
  font-weight: 700;
  font-size: 55px;
  line-height: 43px;

  text-transform: capitalize;
  text-decoration: none;
  list-style: none outside none;

  color: #fefefe;
}
/* description para in box  */
#desc-para {
  /* font-weight: 500;
  font-size: 23px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  margin: 12px;
  font-style: normal; */
  font-family: 'Fira Sans';
  color: #d5d5d5; 
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
display: flex;
align-items: center;
text-align: left;
margin-top: 50px;


}

/* enquire buttom */
#enquire1 {
  padding: 8px 16px;
  margin: 35px 0 35px 0;
  position: absolute;
  bottom: -9px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  border-radius: 50px;
  background: #D9D9D9;
}

/* enqire text  */
#enq-text {
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  text-align: center;
  /* text-decoration-line: underline; */
  color: #9e7642;
  text-decoration: none;
  font-weight:bold;
}

/* footer ke upper  */
.footer-top {
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(142, 150, 162, 0) 30.42%,
    #8e96a2 53.05%
  );
  padding: 113px 0 113px 0;
}

.all-courses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  top: 50rem;
}
.course {
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  color: #555555;
  padding: 8px 16px;
  background: #dee0dc;
  border-bottom: 0.2px solid #e3e8ee;
  border-radius: 8px;
  margin: 10px;
  text-transform: capitalize;
  cursor: pointer;
}

@media screen and (max-width: 1330px) {
  #first-col {
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  #boxs {
    margin: 17px;
    left: 50px
    /* padding-right: 20px; */
    
  }
}

@media screen and (max-width: 750px) {
  #boxs {
    min-width: 340px;
    min-height: 490px;
    left: 15px;
    
  }
  
  #enq-text {
    font-size: 18px;
  }
  #heading-text-box {
    font-size: 30px;
  }
  #desc-para {
    font-size: 21px;
  }

  
}
