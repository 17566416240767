#mobile-nav-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #707070;
    box-shadow: 0 40px 60px rgba(0, 0, 0, 0.1);
    padding: 80px 0 0 10px;
    transition: 0.4s;
    z-index: 1000;
}

#mobile-navbar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: initial;
    gap: 16px;
    padding: 0;
    margin: 0;
}

#mobile-navbar li {
    /* removes bullets in list */
    list-style: none;
    padding: 8px 4px;
    /* makes sure that the content inside should be positioned relative to "#mobile-navbar li"  */
    position: relative;
}

#mobile-navbar li a {
    /* removes the underline on hyperlinks */
    text-decoration: none;
    font-size: 16px;
    font-weight: 600px;
    color: #1a1a1a;
    transition: 0.3s ease;
    /* center */
    display: flex;
    justify-content: center;
}

#mobile-navbar li a:hover,
#mobile-navbar li a.active {
    color: #cabf62;
}

/* adding pseudo element after */
/* #mobile-navbar li a.active::after - add something after content */
#mobile-navbar li a.active::after

/* #mobile-navbar li a:hover::after - now apply properties of "after" class whenever you hover "#mobile-navbar li a" */
,
#mobile-navbar li a:hover::after {
    content: "";
    width: 100%;
    height: 3.39px;
    position: absolute;
    /* left: 20px; */
    bottom: -2px;
    background: #cabf62;
}

#btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
}

#signup,
#login,
#logout,
#dashboard {
    margin: 10px;
    font-size: 20px;
    border-radius: 8px;
    cursor: pointer;
    border: none;
}

#login,
#dashboard {
    border: 1px solid #cabf62;
    background-color: inherit;
    color: #cabf62;
}

#signup,
#logout {
    background: #cabf62;
    margin-left: 10px;
}

#signup a,
#logout a {
    color: black;
    text-decoration: none;
}

#login a,
#dashboard a {
    color: #cabf62;
    text-decoration: none;
}

#mobile-navbar li a {
    font-size: 20px;
}

@media screen and (max-width: 1230px) {

    /*   #navbar {
        padding: 6px 10px;
      } */
    #login,
    #signup,
    #logout,
    #dashboard {
        padding: 12px 27px;
    }
}